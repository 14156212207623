import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import axios from 'axios';

import { matchPath } from 'react-router'

const baseUrl = process.env.REACT_APP_BACKEND_URL;

class EditComponent extends React.Component{

  constructor(props){
    super(props);

    this.state = {
      dataLane:{},
      laneId: "",
      laneIdOriginal: "",
      title:"",
      label:""
    }
  }
  

  componentDidMount(){

    const match = matchPath(this.props.history.location.pathname, {
      path: '/editLane/:param',
      exact: true,
      strict: false
    })

    let userId = match.params.param;
    //console.log(userId);
    const url = baseUrl+"/lanes/get/"+userId
    axios.get(url)
    .then(res=>{
      if (res.data.success) {
        const data = res.data.data[0]
        this.setState({
          dataLane:data,
          laneId:data.laneId.replace('lane',''),
          title:data.title,
          label:data.label
        })
        this.setState({laneIdOriginal: data.laneId.replace('lane','')})
      }
      else {
        alert("Error web service")
      }
    })
    .catch(error=>{
      alert("Error server "+error)
    })
  }

  render(){
    return (
      <div>
        <div className="form-row justify-content-center">
          <div className="form-group col-md-4">
            <label htmlFor="inputPassword4">Lane ID</label>
            <input type="text" className="form-control"  placeholder="Lane ID"
              value={this.state.laneId} onChange={(value)=> this.setState({laneId:value.target.value})}/>
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="inputEmail4">Título</label>
            <input type="text" className="form-control"  placeholder="Título"
              value={this.state.title} onChange={(value)=> this.setState({title:value.target.value})}/>
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="inputEmail4">Rótulo</label>
            <input type="text" className="form-control"  placeholder="Rótulo"
              value={this.state.label} onChange={(value)=> this.setState({label:value.target.value})}/>
          </div>
        </div>
        <button type="submit" style={{ margin: '2px', backgroundColor: '#32d951', border: '0px', color:'#000000' }} className="btn btn-primary" onClick={()=>this.sendUpdate()}>Atualizar</button>
        {'   '}
        <button type="submit" style={{ margin: '2px', backgroundColor: '#32d951', border: '0px', color:'#000000' }} className="btn btn-primary" onClick={()=>this.handleClick()}>Listar Lanes</button>
      </div>
    );
  }

  handleClick () {
    this.props.history.push("/listLane");
  }

  sendUpdate(){
    
    const originalLane = this.state.laneIdOriginal;
    const novaLane = this.state.laneId;

    axios.get(baseUrl + "/lanes/list")
    .then(res => {
      if(res.data.success){
        const data = res.data.data;
        let laneExists = false;
        for (let i = 0; i < data.length; i++) {
          if (data[i].laneId === 'lane' + this.state.laneId) {
            laneExists = true;
            break;
          }
        } 
        if (laneExists) {
          alert('Já existe uma lane com essa ID. Use um valor diferente.');
        }
        else{
          const match = matchPath(this.props.history.location.pathname, {
            path: '/editLane/:param',
            exact: true,
            strict: false
          })     
          let userId = match.params.param;
          // parametros de datos post
          const datapost = {
            laneId : 'lane' + this.state.laneId,
            title : this.state.title,
            label : this.state.label
          }

          axios.post(baseUrl + "/lanes/update/"+userId,datapost)
          .then(response=>{
            if (response.data.success===true) {
              alert(response.data.message)
            }
            else {
              alert("Error")
            }
          }).catch(error=>{
            alert("Error 34 "+error)
          })

          axios.get(baseUrl + "/cards/list")
          .then(res => {
            if(res.data.success){
              const data = res.data.data;
              console.log(originalLane)
              console.log(novaLane)
              function executeForLoop() {
                return new Promise((resolve) => {
                  for(const card of data){
                    const datapost = {
                      laneId : 'lane' + novaLane,
                      ticketId: card.ticketId,
                      title : card.title,
                      description : card.description,
                      label : card.label
                    }
                    if (card.laneId === 'lane' + originalLane){
                      axios.post(baseUrl + "/cards/update/"+card.id,datapost)
                        .then(response=>{
                          if (response.data.success===true) {
                            alert(response.data.message)
                          }
                          else {
                            alert("Error")
                          }
                        }).catch(error=>{
                          alert("Error 34 "+error)
                        })
                    }
                    else {
                      //console.log('falso: ' + card.ticketId)
                    }
                  }
                  resolve();
                });
              }
              executeForLoop().then(() => {
                window.location.reload(true);
              });
            }
            else{
                alert("Error web service");
            }
          })
          .catch(error => {
            alert("Error server " + error)
          });
  
        }
      }
      else{
          alert("Error web service");
      }
    })
    .catch(error => {
      alert("Error server " + error)
    });
   }
}


export default EditComponent;