import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BACKEND_URL;

const YourComponent = () => {
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(baseUrl + '/asaas/get');
        setPayments(response.data.resp);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <table className="table table-hover table-striped">
        <thead className="thead-dark">
          <tr>
            <th>ID</th>
            <th>Data</th>
            <th>Cliente</th>
            <th>Status</th>
            <th>Valor</th>
            <th>Vencimento</th>
            <th>Fatura</th>
          </tr>
        </thead>
        <tbody>
          {payments.map((payment, index) => (
            <tr key={index}>
              <td>{payment.id}</td>
              <td>{format(new Date(payment.dateCreated), 'dd/MM/yyyy')}</td>
              <td>{payment.customer}</td>
              <td>{payment.status === 'OVERDUE' ? 'Vencida' : payment.status === 'PENDING' ? 'Pendente' : payment.status}</td>
              <td>R${payment.value}</td>
              <td>{format(new Date(payment.dueDate), 'dd/MM/yyyy')}</td>
              <td><a href={payment.invoiceUrl} target="_blank" rel="noopener noreferrer">{payment.invoiceUrl}</a></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default YourComponent;
