import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import axios from 'axios';

import { Link } from 'react-router-dom';

//sweetalert2
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

const baseUrl = process.env.REACT_APP_BACKEND_URL;

class listComponent extends React.Component  {
  
  constructor(props){
      super(props);
      this.state = {
          listCards:[]
      }
  }  

  componentDidMount(){
    this.loadCards();
  }

  loadCards(){
    axios.get(baseUrl + "/cards/list")
    .then(res => {
      if(res.data.success){
        const data = res.data.data;
        this.setState({ listCards:data });
      }
      else{
          alert("Error web service");
      }
    })
    .catch(error => {
      alert("Error server " + error)
    });
  }
    
  render()
  {
    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent2" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
          </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent2">
              <Link style={{ margin: '2px', backgroundColor: '#32d951', border: '0px', color:'#000000' }} className="btn btn-info " to="/formCard">Adicionar Card</Link>
              {'   '}
              <Link style={{ margin: '2px', backgroundColor: '#32d951', border: '0px', color:'#000000' }} className="btn btn-info " to="/listKanban">Voltar</Link>
          </div>
        </nav>   
      <br></br>
      <hr></hr>
      <table className="table table-hover table-striped">
        <thead className="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Lane ID</th>
            <th scope="col">Ticket ID</th>
            <th scope="col">Título</th>
            <th scope="col">Descrição</th>
            <th scope="col">Rótulo</th>
            <th scope="col">Editar</th>
            <th scope="col">Deletar</th>
          </tr>
        </thead>
        <tbody>
        {this.loadFillData()}
        </tbody>
      </table>
      </div>
    );
  }

  loadFillData(){
    return this.state.listCards.map((data)=>{
        return(
          <tr key={"datalane" + data.id}>
            <th >{data.id}</th>
            <td>{data.laneId}</td>
            <td>{data.ticketId}</td>
            <td>{data.title}</td>
            <td>{data.description}</td>
            <td>{data.label}</td>
            <td>
              <Link className="btn btn-outline-info" style={{ margin: '2px', backgroundColor: '#32d951', border: '0px', color:'#000000' }} to={"/editCard/"+data.id}>Editar</Link>
            </td>
            <td>
              <button className="btn btn-outline-danger" onClick={()=>this.onDelete(data.id)}>Deletar </button>
            </td>
          </tr>
        )
      });
  }

  onDelete(id){
    Swal.fire({
      title: 'Você tem certeza?',
      text: 'O dado não poderá ser recuperado ' + id,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, eu quero deletar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.sendDelete(id)
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelado',
          'Seu arquivo está a salvo :)',
          'error'
        )
      }
    })
  }

  sendDelete(userId)
  {
    // network
    axios.post(baseUrl + "/cards/delete",{
      id:userId
    })
    .then(response =>{
      if (response.data.success) {
        Swal.fire(
          'Deletado!',
          'Seu card foi removido.',
          'success'
        )
        this.loadCards();
      }
    })
    .catch ( error => {
      alert("Error 325 ")
    })
  }

}

export default listComponent;